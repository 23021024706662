<template>
  <v-row class="justify-space-around">
    <v-sheet
      v-if="q !== undefined"
      class="col-11 mt-5"
    >
      <v-row class="align-center mx-1 justify-space-between">
        <h4 class="justify-center mr-1">
          一問一答
        </h4>
        <p
          v-show="questionSolved &amp;&amp; !hideAnswerMode"
          class="font-weight-light body-2 ma-auto"
        >
          現在の成績：{{ questionSolved }}問中{{ questionCorrected }}問正解 （正答率{{ Math.round(questionCorrected / questionSolved * 100) }}%）
        </p>
        <v-row class="justify-sm-end justify-space-between mx-0">
          <v-btn
            v-if="!hideAnswerMode"
            outlined="outlined"
            color="primary"
            @click="showResult = true"
          >
            現在の成績
          </v-btn>
        </v-row>
      </v-row>
      <v-card class="mt-3">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              headline="headline"
              mb-1="mb-1"
            >
              第{{ page }}問
            </v-list-item-title>
            <v-list-item-subtitle class="font-weight-light">
              {{ subtitleText }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-tooltip top="top">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="isLogin"
                v-bind="attrs"
                large="large"
                :color="q.flg ? 'green' : 'grey'"
                v-on="on"
                @click="toggleFlg"
              >
                mdi-bookmark-plus
              </v-icon>
            </template>ブックマーク
          </v-tooltip>
        </v-list-item>
        <!-- カテゴリの表示-->
        <v-row class="ma-auto px-4">
          <div class="flex align-self-end">
            <v-chip
              small="small"
              outlined="outlined"
            >
              {{ q.level0 }}
            </v-chip><span class="font-weight-thin">/</span>
            <v-chip
              small="small"
              outlined="outlined"
            >
              {{ q.level1 }}
            </v-chip>
            <template v-if="q.level2 &amp;&amp; q.level2 !== 'TBD' ">
              <span class="font-weight-thin">/</span>
              <v-chip
                small="small"
                outlined="outlined"
              >
                {{ q.level2 }}
              </v-chip>
            </template>
          </div>
          <v-spacer />
          <memo
            v-if="isLogin"
            :memo="q.memo"
          />
        </v-row>
        <!--問題文-->
        <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->
        <v-card-text
          class="font-weight-black statement"
          v-html="q.statement" 
        />
        <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
        <div
          v-if="showImage"
          class="mt-3 pa-3"
        >
          <v-img
            class="mx-auto"
            :src="q.statement_image_url"
            contain="contain"
            max-width="400"
          />
        </div>
        <!--回答ボタン-->
        <v-row class="mx-1 mr-3">
          <v-btn
            class="ma-3"
            color="primary"
            :disabled="answered &amp;&amp; selectedAnswer === 0"
            @click="isAnswerCorrect(1)"
          >
            <v-icon>mdi-circle-outline</v-icon>
          </v-btn>
          <v-btn
            class="ma-3"
            color="primary"
            :disabled="answered &amp;&amp; selectedAnswer === 1"
            plain="plain"
            @click="isAnswerCorrect(0)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="!hideAnswerMode &amp;&amp; !answered"
            class="ma-3"
            outlined="outlined"
            color="primary"
            @click="isAnswerCorrect(!q.answer)"
          >
            分からないので答えを見る
          </v-btn>
          <!-- 正解・失敗のdialog-->
          <v-fade-transition>
            <v-overlay
              v-if="overlay &amp;&amp; q.correct"
              color="success"
              absolute="absolute"
              light="light"
              opacity="0.1"
            >
              <v-card
                v-show="overlay"
                color="success"
                width="200"
                @click="overlay = false"
              >
                <v-row justify="center">
                  <v-card-title class="text--black">
                    正解
                  </v-card-title>
                </v-row>
              </v-card>
            </v-overlay>
            <v-overlay
              v-if="overlay &amp;&amp; !q.correct"
              color="red"
              absolute="absolute"
              light="light"
              opacity="0.1"
            >
              <v-card
                v-show="overlay"
                color="red"
                width="200"
                @click="overlay = false"
              >
                <v-row justify="center">
                  <v-card-title class="text--black">
                    不正解
                  </v-card-title>
                </v-row>
              </v-card>
            </v-overlay>
          </v-fade-transition>
        </v-row>
      </v-card>
      <!--解説-->
      <v-expand-transition>
        <question-description
          v-show="show_ans"
          :ans="q.answer"
          :desc="q.description"
          :image-url="q.image_url"
          :show-answer="show_ans"
          :first-correct-rate="q.first_correct_rate"
          @desc-modal="showModal"
        />
      </v-expand-transition>
      <!-- 法律モーダル-->
      <v-fade-transition>
        <v-dialog
          v-if="modalToShow"
          v-model="overlayModal"
          width="700px"
        >
          <law-modal :law-text="modalToShow" />
        <!--v-card.pa-5
        v-card-text(v-html="modalToShow")
        
        -->
        </v-dialog>
      </v-fade-transition>
      <v-row class="justify-space-around mt-5 mx-1">
        <v-btn
          v-if="finished || isLast &amp;&amp; !hideAnswerMode"
          color="orange"
          block="block"
          dark="dark"
          @click.stop="showResult = true"
        >
          {{ isLogin ? '全問終了して結果送信画面に移る' : '全問終了' }}
        </v-btn>
        <v-btn
          v-else-if="isLast &amp;&amp; hideAnswerMode"
          color="orange"
          block="block"
          dark="dark"
          @click="setFinished"
        >
          すべて解き終わったので回答を表示する
        </v-btn>
        <v-btn
          v-else
          color="blue lighten-2"
          block="block"
          dark="dark"
          @click="nextQuestion"
        >
          次の問題
        </v-btn>
      </v-row>
      <v-row class="ma-3 justify-space-around">
        <v-pagination
          :value="page"
          :length="lastPage"
          total-visible="10"
          @input="e =&gt; jumpPage(e)"
        />
      </v-row>
    </v-sheet>
    <v-flex>
      <question-ads
        class="mx-10"
        :ad-to-show="adToShow"
      />
    </v-flex>
    <v-dialog v-model="showResult">
      <result @closeResult="showResult = false" />
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import Result from "../components/Question/Result";
import QuestionDescription from "../components/Question/QuestionDescription";
import LawModal from "../components/Question/LawModal";
import QuestionAds from "@/components/Ads/QuestionAds";
import Memo from "@/components/Question/Memo.vue";

export default {
  name: "Question",
  components: { Memo, QuestionAds, LawModal, Result, QuestionDescription, },
  data() {
    return {
      show_ans: false,
      overlay: false,
      showResult: false,
      modalToShow: null,
      overlayModal: false,
      showCPAAds: false,
      adToShow: 'google'
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'isLogin',
      isLast: 'isLastQuestion',
      q: 'getQuestionByPage',
      lastPage: 'lastPageNum',
      page: 'currentPage',
      questionCorrected: 'questionCorrected',
      questionSolved: 'questionSolved',
      hideAnswerMode: 'hideAnswerMode',
      finished: 'finished',
      userName: 'userName',
    }),
    selectedAnswer() {
      if (!this.answered) return '';
      return this.q.correct ? Number(this.q.answer) : Number(!this.q.answer)
    },
    showImage() {
      return this.q.statement_image_url && this.q.statement_image_url.startsWith('http')
    },
    answered() {
      return this.q.correct !== '';
    },
    subtitleText() {
      return `${this.q.exam} 問${this.q.question_num}`
    }
  },
  watch: {
    overlay(val) {
      if (!val) return;

      setTimeout(() => {
        this.overlay = false;
        this.show_ans = true;
      }, 1000)
    },
    page() {
      if (this.hideAnswerMode) {
        this.show_ans = this.finished;
      } else {
        this.show_ans = this.answered;
      }
    },
    show_ans() {
      this.$store.dispatch('updateExpiredAt');
    }
  },
  mounted() {
    this.reRender();
  },
  updated() {
    this.$store.dispatch('updateLastQuestionPage', this.page);
  },
  methods: {
    reRender() {
      if (window.MathJax) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
      }
    },
    jumpPage(page) {
      this.$router.push({ name: 'question', params: { page: page } });
    },
    nextQuestion() {
      if (!this.isLast) this.jumpPage(this.page + 1);
    },
    toggleFlg() {
      this.$store.dispatch('toggleQuestionFlg');
      this.$store.dispatch('updateExpiredAt');
    },
    isAnswerCorrect(n) {
      if (this.finished) return;
      if (this.answered) return;

      this.q.answer === n ? this.$store.dispatch('answerCorrect') : this.$store.dispatch('answerWrong');
      this.hideAnswerMode ? this.nextQuestion() : this.overlay = true;
    },
    showModal(e) {
      this.overlayModal = true;
      this.modalToShow = this.q.modals[e];
    },
    setFinished() {
      this.$store.dispatch('updateFinished', true)
      this.jumpPage(1)
    }
  },
  beforeRouteUpdate(from, to, next) {
    window.refreshQuestionAdsSlot();
    const random = Math.random()
    if (random < 1 / 7) {
      this.adToShow = 'CPA'
    } else if (1 / 7 <= random && random < 2 / 7) {
      this.adToShow = 'BDO'
    } else if (2 / 7 <= random && random < 3 / 7) {
      this.adToShow = 'growth'
    } else {
      this.adToShow = 'google'
    }
    next()
  },
}
</script>

<style scoped>
.responsive-img >>> img {
  max-width: 500px;
  min-width: 100px;
  width: 100%;
}

.statement {
  white-space: pre-line
}

.statement >>> p {
  margin-bottom: 0 !important;
}
</style>

